export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if ((user && user.token) || (user && user.access_token)) {
        let token = user.token ? user.token : user.access_token;
        if (!token) return {};
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}